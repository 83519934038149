<template>
  <div>

    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-component _2 w-nav">
      <div class="navbar-container w-container">
        <a id="w-node-_2bc7849c-fc53-2a49-059f-aa960ee8e78d-0ee8e78b" @click="goToHome" class="navbar-logo-link no-rizer w-nav-brand"><img src="../../../../../public/images/Logo.svg" loading="lazy" alt="" class="navbar-logo"></a>
      </div>
    </div>
    <div class="section color mid-line">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container last form one w-container">
        <div class="form-regidet w-form">
          <div class="wrap-steps">
            <a @click="previousPage" class="arrow-left w-inline-block"><img src="../../../../../public/images/Union.svg" loading="lazy" alt="" class="arrow"></a>
            <div class="step">
              <div class="step-number like">
                <div>1</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number like">
                <div class="text-block-7">2</div>
              </div>
            </div>
            <div class="step">
              <div class="step-number active">
                <div class="text-block-8">3</div>
                <div data-w-id="a1680b49-2005-863e-9cc8-d83ed7d2e3cb" class="pulse"></div>
              </div>
            </div>
            <div class="step">
              <div class="step-number">
                <div>4</div>
              </div>
            </div>
          </div>
          <form id="wf-form-signup-2" name="wf-form-signup-2" data-name="signup" method="get" data-ms-form="signup" class="form-input-regidet" data-wf-page-id="6666afa0475bacb265fce522" data-wf-element-id="d73203a7-f081-4301-5c74-acb811806f90">
            <v-form ref="formReg3" v-model="valid_reg3" lazy-validation>
              <div class="line-progress step-3"></div>
              <div class="tittle-form">
                <h1 class="heading-form" style="margin-bottom: 15px">Дополнительная информация</h1>
              </div>
              <div class="w-layout-vflex flex-form">
                <div class="w-layout-hflex flex-input">
                  <div class="input-box">
                    <label for="Basic-income" class="input-label">Основной доход, в рублях</label>
                    <v-text-field
                        outlined
                        type="number"
                        inputmode="numeric"
                        v-model="registration_data.incomes.primary_income"
                        :rules="incomeRules"
                    ></v-text-field>
                  </div>

                  <div class="input-box">
                    <label for="Field-of-activity" class="input-label">Сфера деятельности</label>
                    <select
                        v-model="registration_data.incomes.sector_id"
                        id="Field-of-activity"
                        name="Field-of-activity"
                        data-name="Field of activity"
                        required=""
                        class="input-form w-select"
                    >
                      <!-- Используем v-for для отображения каждого сектора в виде опции -->
                      <option v-for="sector in sectors" :key="sector.id" :value="sector.id">
                        {{ sector.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="w-layout-hflex flex-input">
                  <div class="input-box _50">
                    <label for="Additional-income" class="input-label">Дополнительный доход, в рублях</label>
                    <v-text-field
                        outlined
                        type="text"
                        v-model="registration_data.incomes.secondary_income"
                        :rules="incomeRules"
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <a id="openLabel" @click="nextPage" class="button-bust w-button">Продолжить</a>
              <link rel="prerender" @click="nextPage">
            </v-form>
          </form>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'RegistrationStep3',
  data() {
    return {
      valid_reg3: false,
      incomeRules: [
        // Поле не может быть пустым, разрешаем "0"
        v => (v === 0 || !!v) || 'Введите доход',
        v => /^\d+$/.test(v) || 'Доход должен содержать только числа'
      ]
    };
  },
  components: {
    Footer
  },
  created() {
    this.$store.dispatch('GET_SECTORS_LIST').catch({
      error: () => {
        this.$buefy.toast.open({
          message: 'Ошибка на сервере. Попробуйте позже',
          type: 'is-danger'
        });
      }
    });
  },
  computed: {
    sectors() {
      return this.$store.getters.SECTORS;
    },
    registration_data: {
      get() {
        return this.$store.getters.REGISTRATION_DATA;
      },
      set(value) {
        this.$store.commit('SET_REGISTRATION_DATA', value);
      }
    },
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    goToHome() {
      this.$router.push('/');
    },
    nextPage() {
      if (this.$refs.formReg3.validate()) {
        this.$store.dispatch('REGISTRATION', this.registration_data)
            .then(() => {
              this.$router.push('/registration-step4');
            })
            .catch((error) => {
              this.$buefy.toast.open({
                message: error,
                type: 'is-danger'
              });
            });
      } else {
        this.$buefy.toast.open({
          message: 'Неправильно заполнены поля',
          type: 'is-danger'
        });
      }
    },
    previousPage() {
      this.$router.push('/registration-step2');
    }
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });
          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
</style>
